import { Image } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import leftArrow from "../../Assets/sidebar/arrows/left.svg";
import rightArrow from "../../Assets/sidebar/arrows/right.svg";
import { SidebarContext } from "Context/SidebarContext";

const Sidebar = () => {
  const icons = require.context("../../Assets/sidebar", true);
  const iconsList = icons.keys().map((image) => icons(image));
  const sidebarData = [
    {
      title: "My fronds",
      link: "/",
    },
    {
      title: "My Tasks",
      link: "my-tasks",
    },
    {
      title: "Discover",
      link: "discover",
    },
    {
      title: "Community",
      link: "community",
    },
    {
      title: "Help & support",
      link: "help-support",
    },
    {
      title: "Contact us",
      link: "contact-us",
    },
  ];

  const { pathname } = useLocation();
  useEffect(() => {}, [pathname]);

  const formatPathname = (path) => {
    if (path === "/") return path;
    return path.replace(/\/+$/, "");
  };

  const formattedPathname = formatPathname(pathname);

  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [showText, setShowText] = useState(true);

  const handleSidebarToggle = () => {
    if (isSideBarOpen) {
      setShowText(false);
    } else {
      setTimeout(() => {
        setShowText(true);
      }, 300);
    }
    setIsSideBarOpen(!isSideBarOpen);
    setIsOpen(!isOpen);
  };

  const { isOpen, setIsOpen } = useContext(SidebarContext);

  return (
    <aside
      className={`sidebar bg-white px-[18px] py-[30px] min-h-[600px] ${
        isSideBarOpen
          ? "min-w-[283px] max-w-[283px] relative"
          : "min-w-[84px] max-w-[84px] absolute"
      } transition-all duration-400 border-2 border-solid border-grayBorder rounded-4xl flex flex-col gap-4`}
    >
      <button
        onClick={handleSidebarToggle}
        className={`transition-all w-[24px] h-[24px] duration-1000 absolute top-[12px] right-[-12px] bg-mainGradiant rounded-[5px] flex justify-center items-center`}
        style={{
          opacity: isSideBarOpen ? "1" : "0",
          visibility: isSideBarOpen ? "visible" : "hidden",
        }}
      >
        <Image src={leftArrow} alt="<" className="size-full" />
      </button>
      <button
        onClick={handleSidebarToggle}
        className={`transition-all w-[24px] h-[24px] duration-1000 absolute top-[12px] right-[-12px] bg-mainGradiant rounded-[5px] flex justify-center items-center`}
        style={{
          opacity: !isSideBarOpen ? "1" : "0",
          visibility: !isSideBarOpen ? "visible" : "hidden",
        }}
      >
        <Image src={rightArrow} alt=">" className="size-full" />
      </button>
      {sidebarData.map((item, idx) => (
        <Link
          to={item.link}
          key={idx}
          className={`${
            idx === 1 || idx === 3 ? "mb-[10px]" : ""
          } flex items-center`}
        >
          <div
            className={`flex gap-4 rounded-[10px] w-full ${
              isSideBarOpen ? "px-15px" : "px-[10px]"
            } py-2.5 ${
              idx === 1 || idx === 3
                ? "relative after:absolute after:left-0 after:right-0 after:h-px after:bg-[#EDEAFF] after:bottom-[-15px]"
                : ""
            } ${
              item.link === formattedPathname ||
              (item.link === "/" && formattedPathname === "/home")
                ? "bg-[#F5F6FF]"
                : "bg-white"
            }`}
          >
            <Image
              src={iconsList[idx]}
              alt={item.title}
              className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px]"
            />
            <h5
              className={`text-textColor ${
                item.link === formattedPathname ||
                (item.link === "/" && formattedPathname === "/home")
                  ? "font-bold"
                  : "font-normal"
              } ${!showText ? "hidden" : ""}`}
            >
              {item.title}
            </h5>
          </div>
        </Link>
      ))}
    </aside>
  );
};

export default Sidebar;
