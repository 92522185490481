import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Image, Tab, Tabs } from "@nextui-org/react";
import {
  ArchiveX,
  Share2,
  SquarePen,
  Target,
  UserPlus,
  Users,
} from "lucide-react";

const ControlledTabs = () => {
  const activeIcons = require.context("../../Assets/tabs/active", true);
  const activeIconsList = activeIcons.keys().map((image) => activeIcons(image));
  const nonActiveIcons = require.context("../../Assets/tabs/non-active", true);
  const nonActiveIconsList = nonActiveIcons
    .keys()
    .map((image) => nonActiveIcons(image));

  const { pathname } = useLocation();
  useEffect(() => {}, [pathname]);

  const formatPathname = (path) => {
    if (path === "/") return path;
    return path.replace(/\/+$/, "");
  };

  const formattedPathname = formatPathname(pathname);

  const tabsData = [
    {
      title: "My fronds",
      link: "/",
    },
    {
      title: "Create a frond",
      link: "/create-frond",
    },
    {
      title: "Shared fronds",
      link: "/shared-fronds",
    },
    {
      title: "Invitations",
      link: "/invitations",
    },
    {
      title: "Archive",
      link: "/archived-fronds",
    },
  ];

  return (
    <div className="tabs flex justify-between items-center bg-white w-full border-2 border-solid border-grayBorder rounded-4xl py-2.5 px-[9px] gap-12 mb-5">
      {tabsData.map((item, idx) => (
        <Link
          key={idx}
          to={item.link}
          className={`flex gap-4 justify-center items-center rounded-[40px] py-2.5 px-5 transition-all ${
            item.link === formattedPathname ||
            (item.link === "/" && formattedPathname === "/home") ||
            (item.link === "/" && formattedPathname === "/my-fronds")
              ? "bg-mainGradiant"
              : "bg-white"
          }`}
          style={{
            transition: "background 0.3s",
          }}
        >
          <Image
            src={
              item.link === formattedPathname ||
              (item.link === "/" && formattedPathname === "/home") ||
              (item.link === "/" && formattedPathname === "/my-fronds")
                ? activeIconsList[idx]
                : nonActiveIconsList[idx]
            }
            style={{ color: "red", fill: "red" }}
          />
          <h5
            className={`text-lg ${
              item.link === formattedPathname ||
              (item.link === "/" && formattedPathname === "/home") ||
              (item.link === "/" && formattedPathname === "/my-fronds")
                ? "text-white font-bold"
                : "text-cardGrayColor font-normal"
            }`}
          >
            {item.title}
          </h5>
        </Link>
      ))}
    </div>
  );
};

export default ControlledTabs;
