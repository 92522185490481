import React from "react";
import largePhone from "../../Assets/landing/largePhone.svg";
import smallPhone from "../../Assets/landing/smallPhone.svg";
import googlePlay from "../../Assets/landing/googlePlay.svg";
import appStore from "../../Assets/landing/appStore.svg";

const Goals = () => {
  return (
    <section className="goals py-12 bg-goalsGradiant relative">
      <div className="absolute top-0 md:bottom-0 bottom-[50%] start-0 md:end-[50%] end-0 bg-goalsImg bg-cover bg-no-repeat z-10"></div>
      <div className="container xl:px-44 px-6 mx-auto relative z-20">
        <div className="goals-card grid md:grid-cols-2 gap-10 items-center">
          <div className="card-img relative">
            <img src={largePhone} alt="Phone" className="relative z-20" />
            <img
              src={smallPhone}
              alt="Phone"
              className="absolute md:start-[29%] sm:start-[27%] start-[17%] bottom-0 z-10 small-phone-img"
            />
          </div>
          <div className="card-content">
            <h3 className="bg-clip-text text-transparent bg-mainGradiant font-bold sm:text-38px text-3xl mb-8 leading-HeadingHeight">
              Complete your personal projects and realise your goals with Frond
            </h3>
            <p className="font-normal text-lg leading-textHeight text-black opacity-75 mb-6">
              Frond is a personal project management app that brings your plans
              to life. Whether you're organising the trip of a lifetime,
              planning a home-renovation or organising a wedding, Frond can help
              you keep track of your ideas and inspirations, manage timelines
              and budgets add collaborators, and share your projects with
              friends and family to make your plans come to life.
            </p>
            <div className="download flex gap-4 items-center sm:flex-nowrap flex-wrap">
              <img src={googlePlay} alt="Google play" />
              <img src={appStore} alt="App store" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Goals;
