import React from "react";
import { createRoot } from "react-dom/client";

// App
import { App } from "./App";

// Redux Store
import { Provider } from "react-redux";
import { store } from "./store/store";

// Main CSS
import "./styles/app.css";
import { ClerkProvider } from "@clerk/clerk-react";

import "./index.css";
import AuthContextProvider from "./Context/AuthContext";

import "@fortawesome/fontawesome-free/css/all.min.css";
import SidebarContextProvider from "Context/SidebarContext";

// const Root = document.getElementById("root");

const PUBLISHABLE_KEY =
  "pk_test_ZmlybS1zcGlkZXItNTEuY2xlcmsuYWNjb3VudHMuZGV2JA";
// process.env.REACT_APP_API_ENDPOINT_URL

if (!PUBLISHABLE_KEY) {
  throw new Error("hady");
}

function Root() {
  return (
    <Provider store={store}>
      <AuthContextProvider>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
          <SidebarContextProvider>
            <App />
          </SidebarContextProvider>
        </ClerkProvider>
      </AuthContextProvider>
    </Provider>
  );
}

createRoot(document.getElementById("root")).render(<Root />);
