import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "Context/AuthContext";
import OTPInput from "react-otp-input";
import { useSignUp } from "@clerk/clerk-react";

const VerifyPassword = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  const { setIsVerified } = useContext(AuthContext);

  useEffect(() => {
    otp.length === 6 ? setIsDisabled(false) : setIsDisabled(true);
  }, [otp]);

  const { signUp, setActive } = useSignUp();

  async function callVerify() {
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);

    if (otp.match(/^[0-9]{6}$/)) {
      try {
        const completeSignUp = await signUp.attemptEmailAddressVerification({
          code: otp,
        });

        // If verification was completed, set the session to active
        // and redirect the user
        if (completeSignUp.status === "complete") {
          await setActive({ session: completeSignUp.createdSessionId });
          setIsVerified(true);
          localStorage.setItem("token", completeSignUp.createdSessionId);
          setToken(completeSignUp.createdSessionId);
          navigate("/");
        } else {
          // If the status is not complete, check why. User may need to
          // complete further steps.
          setIsLoading(false);
          setErrorMessage("An error occurred");
        }
      } catch (error) {
        setErrorMessage(error.errors ? error.errors[0].message : error.message);
        setIsLoading(false);
      }
    } else {
      setErrorMessage("OTP must be 6 digits");
      setIsLoading(false);
    }
    setIsDisabled(false);
  }

  async function resendOtp() {}

  function getOtp(t) {
    setOtp(t);
  }

  useEffect(() => {}, [otp]);

  return (
    <section className="verify-password h-[calc(100vh-85px)] flex justify-center items-center relative overflow-x-hidden">
      <div className="absolute bottom-0 left-0 w-[450px] h-[412px] bg-authImg1 bg-cover bg-no-repeat z-0"></div>
      <div className="absolute top-[30px] right-0 w-[214px] h-[287px] bg-authImg2 bg-cover bg-no-repeat z-0"></div>
      <div className="container xl:px-44 sm:px-12 px-4 mx-auto relative z-10">
        <div className="box sm:max-w-[395px] max-w-[310px] mx-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white">
          <h2 className="font-bold text-[20.72px] mb-1 text-center">
            Confirm OTP
          </h2>
          <p className="text-cardGrayColor text-base text-center mb-6">
            Check your e-mail example@mail.com
          </p>
          <div className="verify-password-form mb-8">
            {errorMessage && (
              <div className="text-white bg-cardColor py-3 mb-10 text-center rounded-15px text-sm">
                <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                  {errorMessage}
                </span>
              </div>
            )}
            <div className="mb-8">
              <OTPInput
                value={otp}
                onChange={getOtp}
                numInputs={6}
                containerStyle={"justify-between"}
                renderInput={(props, idx) => {
                  return (
                    <input
                      data-id={idx}
                      value={otp}
                      {...props}
                      className={`otp-input border-[0.86px] border-solid ${
                        props.value.length > 0
                          ? "border-[#5CCDB0] focusBorder"
                          : "border-cardColor normalBorder"
                      } rounded-[8.64px] sm:min-w-[46px] sm:h-[40px] min-w-[36px] h-[36px] bg-white text-[15.55px] font-normal text-[#2B2B2B]`}
                    />
                  );
                }}
              />
            </div>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                callVerify();
              }}
              disabled={isDisabled}
              className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
            >
              {isLoading ? (
                <span className="flex gap-2 justify-center items-center">
                  <span>Loading</span>
                  <i className="fa-solid fa-spinner fa-spin text-white"></i>
                </span>
              ) : (
                "Confirm OTP"
              )}
            </button>
          </div>
          <div className="sign-up text-center text-[13.81px]">
            Didn’t receive code?
            <button
              onClick={(e) => {
                e.preventDefault();
                resendOtp();
              }}
              className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
            >
              Resend OTP
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyPassword;
