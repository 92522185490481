import { SidebarContext } from "Context/SidebarContext";
import ControlledTabs from "components/ControlledTabs/ControlledTabs";
import ProfileNavbar from "components/ProfileNavbar/ProfileNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  const { isOpen } = useContext(SidebarContext);

  useEffect(() => {}, [isOpen]);

  return (
    <>
      <ProfileNavbar />
      <div className="wrapper flex items-start m-5 gap-5">
        <Sidebar />
        <div className={`page ${isOpen ? "w-full mx-0" : "mx-auto"}`}>
          <ControlledTabs />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
