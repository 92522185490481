import { Card, CardBody, CardHeader, Image } from "@nextui-org/react";
import React from "react";
import feedbackCardImg from "../../Assets/landing/feedbackCardImg.svg";

const Feedback = () => {
  return (
    <section className="feedbak py-12">
      <div className="container xl:px-20 px-6 mx-auto">
        <h2 className="bg-clip-text text-transparent bg-mainGradiant text-38px font-bold text-center mb-12">
          Users Feedback
        </h2>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-9">
          <Card
            className="p-10 rounded-20px border-2 border-solid border-cardColor shadow-cardNonActiveShadow transition-all duration-250 hover:scale-105 hover:shadow-cardActiveShadow"
          >
            <CardHeader className="flex gap-4 p-0 mb-6">
              <Image
                alt="Feedback"
                src={feedbackCardImg}
                className="w-[73px] h-[73px] rounded-full"
              />
              <div className="flex flex-col gap-2">
                <h4 className="text-2xl font-bold">Caroline Purton</h4>
                <p className="text-lg text-cardGrayColor">Wedding Planner</p>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <p className="text-2xl opacity-75 leading-[33.6px]">
                “ I never thought I would enjoy taking on new projects quite so
                much! With frond I can't get enough! “
              </p>
            </CardBody>
          </Card>
          <Card className="p-10 rounded-20px border-2 border-solid border-cardColor shadow-cardNonActiveShadow transition-all duration-250 hover:scale-105 hover:shadow-cardActiveShadow focus:border-cardColor">
            <CardHeader className="flex gap-4 p-0 mb-6">
              <Image
                alt="Feedback"
                src={feedbackCardImg}
                className="w-[73px] h-[73px] rounded-full"
              />
              <div className="flex flex-col gap-2">
                <h4 className="text-2xl font-bold">Caroline Purton</h4>
                <p className="text-lg text-cardGrayColor">Wedding Planner</p>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <p className="text-2xl opacity-75 leading-[33.6px]">
                “ I never thought I would enjoy taking on new projects quite so
                much! With frond I can't get enough! “
              </p>
            </CardBody>
          </Card>
          <Card className="lg:col-span-1 md:col-span-2 col-span-1 p-10 rounded-20px border-2 border-solid border-cardColor shadow-cardNonActiveShadow transition-all duration-250 hover:scale-105 hover:shadow-cardActiveShadow">
            <CardHeader className="flex gap-4 p-0 mb-6">
              <Image
                alt="Feedback"
                src={feedbackCardImg}
                className="w-[73px] h-[73px] rounded-full"
              />
              <div className="flex flex-col gap-2">
                <h4 className="text-2xl font-bold">Caroline Purton</h4>
                <p className="text-lg text-cardGrayColor">Wedding Planner</p>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <p className="text-2xl opacity-75 leading-[33.6px]">
                “ I never thought I would enjoy taking on new projects quite so
                much! With frond I can't get enough! “
              </p>
            </CardBody>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
//              38 33
