import { Input } from "@nextui-org/react";
import React, { useContext, useState } from "react";
import emailIcon from "../../Assets/auth/sms.svg";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import { AuthContext } from "Context/AuthContext";

const ForgetPassword = () => {
  //integration part

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email required"),
  });

  const forgetForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: callForget,
  });

  const { setOtp } = useContext(AuthContext);

  async function callForget(reqBody) {
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);
    // try {
    //   const {data} = axios.post();
    //   if (data.message === "success") {
    //     setOtp(otp);
    //     navigate("/verify-password");
    //   }
    // } catch (error) {
    //   setErrorMessage(error);
    //   setIsLoading(false);
    // }
    setIsDisabled(false);
  }
  return (
    <section className="forget-password h-[calc(100vh-85px)] flex justify-center items-center relative overflow-x-hidden">
      <div className="absolute bottom-0 left-0 w-[450px] h-[412px] bg-authImg1 bg-cover bg-no-repeat z-0"></div>
      <div className="absolute top-[30px] right-0 w-[214px] h-[287px] bg-authImg2 bg-cover bg-no-repeat z-0"></div>
      <div className="container xl:px-44 sm:px-6 px-4 mx-auto relative z-10">
        <div className="box sm:mx-auto sm:w-[395px] w-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white">
          <h2 className="font-bold text-[20.72px] mb-6 text-center">
            Forgot Password?
          </h2>
          <div className="forget-password-form mb-8">
            {errorMessage && (
              <div className="text-white bg-black py-3">{errorMessage}</div>
            )}
            <form onSubmit={forgetForm.handleSubmit}>
              <div className="mb-8">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  variant="bordered"
                  placeholder="Enter your email"
                  labelPlacement="outside"
                  startContent={<img src={emailIcon} />}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={forgetForm.handleChange}
                  onBlur={forgetForm.handleBlur}
                  value={forgetForm.values.email}
                  isInvalid={forgetForm.errors.email}
                  errorMessage={
                    forgetForm.errors.email && forgetForm.touched.email
                      ? forgetForm.errors.email
                      : null
                  }
                />
              </div>
              <button
                type="submit"
                disabled={!(forgetForm.isValid && forgetForm.dirty) || isDisabled}
                className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center">
                    <span>Loading</span>
                    <i className="fa-solid fa-spinner fa-spin text-white"></i>
                  </span>
                ) : (
                  "Reset password"
                )}
              </button>
            </form>
          </div>
          <div className="sign-up text-center text-[13.81px]">
            Don’t have an account?
            <Link
              to={"/signup"}
              className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
