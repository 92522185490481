import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Image,
  ModalFooter,
} from "@nextui-org/react";
import closeBtn from "../../Assets/landing/closeBtn.svg";

const PrivacyPolicy = ({ isOpen, onClose }) => {
  return (
    <Modal
      backdrop="opaque"
      isOpen={isOpen}
      onOpenChange={onClose}
      scrollBehavior="inside"
      placement="center"
      size="3xl"
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            y: -20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        },
      }}
      classNames={{
        header: "text-center font-bold text-xl text-black p-0",
        body: "p-0",
        closeButton: "hidden",
        base: "rounded-4xl max-h-[75vh] mx-2 rights-popUp",
      }}
    >
      <ModalContent>
        <ModalHeader className="flex justify-center items-center relative shadow-privacyShadow py-5 px-5">
          <button onClick={onClose} className="absolute left-5 top-5">
            <Image src={closeBtn} alt="X" />
          </button>
          <span>Privacy Policy</span>
        </ModalHeader>
        <ModalBody className="text-black px-12 py-[34px]">
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              Important information and who we are
            </h3>
            <p className="text-base opacity-75">
              Plannit Limited is the controller and responsible for your
              personal data.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Contact Details</h3>
            <p className="text-base opacity-75">
              Our full details are: Full name of legal entity: Plannit Limited’s
              (trading as “Frond”) Name or title of DPO OR data privacy
              manager]: Mark Ferrari Email address: admin@thefrondapp.com
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              The data we collect about you
            </h3>
            <p className="text-base opacity-75">
              If you consent, we may collect, use, store and transfer different
              kinds of personal data about you as follows: Identity Data
              including name, username or similar identifier, marital status,
              title, date of birth and gender. Contact Data including billing
              address, delivery address, email address and telephone numbers.
              Profile Data including your username and password, purchases or
              orders made by you, your interests, preferences, feedback and
              survey responses. Marketing and Communications Data including your
              preferences in receiving marketing from us and our third parties
              and your communication preferences. Technical information,
              including the type of mobile device you use, a unique device
              identifier (for example, your device’s IMEI number or the mobile
              phone number used by the device), mobile network information, your
              mobile operating system, the type of mobile browser you use,
              (Device Information); Information stored on your device (Content
              Information); Details of your use of any of our Apps or your
              visits to any of Our Sites including, but not limited to traffic
              data, weblogs and other communication data, whether this is
              required for our own billing purposes or otherwise (Log
              Information. We also use GPS technology to determine your current
              location. Some of our location-enabled Services require your
              personal data for the feature to work. If you wish to use the
              particular feature, you will be asked to consent to your data
              being used for this purpose. You can withdraw your consent at any
              time.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              Information we receive from other sources (Third Party
              Information)
            </h3>
            <p className="text-base opacity-75">
              We are working closely with third parties (including, for example,
              business partners, sub-contractors in technical, payment and
              delivery services, advertising networks, analytics providers,
              search information providers, credit reference agencies). We will
              notify you when we receive information about you from them and the
              purposes for which we intend to use that information.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              How we use your Personal Data
            </h3>
            <p className="text-base opacity-75">
              We will only use your personal data for the purpose for which we
              collected it which include the following: To register you as a new
              customer. To manage your relationship with us. To enable you to
              participate in a prize draw, competition or complete a survey. To
              improve our website, products/services, marketing or customer
              relationships. To recommend products or services which may be of
              interest to you. Where we need to comply with a legal obligation.
              Generally, we do not rely on consent as a legal basis for
              processing your personal data although we will get your consent
              before sending third party direct marketing communications to you
              via email or text message. You have the right to withdraw consent
              to marketing at any time by contacting us.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              How we share your Personal Data
            </h3>
            <p className="text-base opacity-75">
              We may share your personal data with external third parties such
              as retailers and service providers in order to deliver tailored
              recommendations and suggestions to you.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">International Transfers</h3>
            <p className="text-base opacity-75">
              We will not transfer, store or process data outside of the EEA.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              Changes to the Privacy Policy and your duty to inform us of
              changes
            </h3>
            <p className="text-base opacity-75">
              We keep our privacy policy under regular review. This version was
              last updated on 27 July 2018. It is important that the personal
              data we hold about you is accurate and current. Please keep us
              informed if your personal data changes during your relationship
              with us.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Third-party links</h3>
            <p className="text-base opacity-75">
              Our app may include links to third-party websites, plug-ins and
              applications. Clicking on those links or enabling those
              connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not
              responsible for their privacy statements. When you leave our app,
              we encourage you to read the privacy policy of every website you
              visit.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Data Security</h3>
            <p className="text-base opacity-75">
              We have put in place appropriate security measures to prevent your
              personal data from being accidentally lost, used or accessed in an
              unauthorised way, altered or disclosed. In addition, we limit
              access to your personal data to those employees, agents,
              contractors and other third parties who have a business need to
              know. They will only process your personal data on our
              instructions and they are subject to a duty of confidentiality. We
              have put in place procedures to deal with any suspected personal
              data breach and will notify you and any applicable regulator of a
              breach where we are legally required to do so.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Data Retention</h3>
            <p className="text-base opacity-75">
              We will only retain your personal data for as long as reasonably
              necessary to fulfil the purposes we collected it for, including
              for the purposes of satisfying any legal, regulatory, tax,
              accounting or reporting requirements. We may retain your personal
              data for a longer period in the event of a complaint or if we
              reasonably believe there is a prospect of litigation in respect to
              our relationship with you. To determine the appropriate retention
              period for personal data, we consider the amount, nature and
              sensitivity of the personal data, the potential risk of harm from
              unauthorised use or disclosure of your personal data, the purposes
              for which we process your personal data and whether we can achieve
              those purposes through other means, and the applicable legal,
              regulatory, tax, accounting or other requirements.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Your Legal Rights</h3>
            <p className="text-base opacity-75">
              Under certain circumstances, you have rights under data protection
              laws in relation to your personal data including the right to
              receive a copy of the personal data we hold about you and the
              right to make a complaint at any time to the Information
              Commissioner’s Office, the UK supervisory authority for data
              protection issues (www.ico.org.uk).
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Further Details</h3>
            <p className="text-base opacity-75">
              If you are looking for more information on we process your
              personal data including on data security, data retention and
              lawful processing bases, please access our website privacy policy.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Further Details</h3>
            <p className="text-base opacity-75">
              If you are looking for more information on we process your
              personal data including on data security, data retention and
              lawful processing bases, please access our website privacy policy.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="shadow-flipPrivacyShadow min-h-[45px] max-h-[45px]"></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrivacyPolicy;
