import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../Assets/landing/arrow-right.svg";
import headerScreenshot from "../../Assets/landing/headerScreenshot.png";
import { Image } from "@nextui-org/react";

const Header = () => {
  return (
    <section className="header bg-mainGradiant flex justify-center items-center h-auto lg:h-section-lg pt-5 lg:pt-0 sm:pb-10 pb-0 lg:pb-0">
      <div className="container xl:px-20 px-6 mx-auto sm:mx-0">
        <div className="flex items-center sm:gap-10 lg:flex-nowrap flex-wrap">
          <div className="content">
            <h1 className="text-50px text-white font-bold mb-10">
              Plan It. Share It. Live It.
            </h1>
            <p className="text-3xl font-bold text-white mb-14">
              Plan, collaborate and celebrate your personal project journey.
            </p>
            <Link
              to={"/signup"}
              className="py-15px px-5 bg-white rounded-5xl w-fit flex items-center gap-4"
            >
              <span className="bg-clip-text text-transparent bg-mainGradiant font-bold text-lg">
                Get Started
              </span>
              <Image src={arrow} />
            </Link>
          </div>
          <div className="header-imgs relative lg:shrink-0 mx-auto sm:w-screenshot-container-w sm:h-screenshot-container-h w-full h-screenshot-container-hr">
            <div className="absolute end-0 sm:top-0 top-10 z-20 rounded-15px sm:w-screenshot-w sm:h-screenshot-h w-4/5 h-screenshot-hr">
              <img
                src={headerScreenshot}
                className="sm:w-screenshot-w sm:h-screenshot-h size-full rounded-15px"
              />
            </div>
            <div className="absolute start-0 sm:bottom-0 bottom-10 z-10 rounded-15px sm:w-screenshot-w sm:h-screenshot-h w-4/5 h-screenshot-hr">
              <img
                src={headerScreenshot}
                className="sm:w-screenshot-w sm:h-screenshot-h size-full rounded-15px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
