import React, { useContext, useEffect } from "react";

// import {Switch, Route} from "react-router-dom";

// URL Routes for pages

// layout
// import { Layout } from "./hocs/Layout";

// Component

// Pages
// import {Activation} from "./pages/Activation/Activation";

import { NextUIProvider, Tab, Tabs } from "@nextui-org/react";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import Layout from "components/Layout/Layout";

import Login from "components/Login/Login";
import Register from "components/Register/Register";
import Homepage from "pages/Homepage/Homepage";
import ForgetPassword from "components/ForgetPassword/ForgetPassword";
import VerifyPassword from "components/VerifyPassword/VerifyPassword";
import ProtectedLogin from "components/ProtectedRoutes/ProtectedLogin";
import ProtectedVerify from "components/ProtectedRoutes/ProtectedVerify";
import { AuthContext } from "Context/AuthContext";
import ProtectedRoutes from "components/ProtectedRoutes/ProtectedRoutes";
import AuthHomePage from "components/AuthHomePage/AuthHomePage";
import Download from "components/Download/Download";
import ContactUs from "components/ContactUs/ContactUs";
import HowFrondWorks from "components/HowFrondWorks/HowFrondWorks";
import FAQ from "components/FAQ/FAQ";
import Events from "components/Tour/Events";
import HomeComponent from "components/Tour/HomeComponent";
import Ventures from "components/Tour/Ventures";
import Escape from "components/Tour/Escape";
import AuthLayout from "components/AuthLayout/AuthLayout";
import MyFronds from "MyFronds/MyFronds";
import SharedFronds from "components/SharedFronds/SharedFronds";
import ArchiveFronds from "components/ArchiveFronds/ArchiveFronds";
import CreateFrond from "pages/CreateAFrond/CreateFrond";

// if (!getApps().length) {
//   const app = initializeApp(firebaseConfig);
//   getAnalytics(app);
// } else {
//   getApp();
// }

export const App = () => {
  //   const clerkUser = useUser();

  //   axios.defaults.baseURL = "https://frond-admin.code-minds.tech/api";
  //   const getData = async function () {
  //     try {
  //       // const firebaseToken = clerkUser.user.getSessions();
  //       const auth = getAuth();

  //       const token = await getToken({ template: "integration_firebase" });
  //       // const userCredentials = await signInWithCustomToken(auth, token);
  //       if (clerkUser.isSignedIn) {
  //         axios
  //           .post("/user", {
  //             name: clerkUser.user.fullName,
  //             email: clerkUser.user.primaryEmailAddress.emailAddress,
  //             password: clerkUser.user.id,
  //           })
  //           .then((res) => {
  //             let authToken = localStorage.setItem(
  //               "token",
  //               res.data.response.data.token
  //             );
  //             axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  //           })
  //           .catch((err) => {
  //             // console.log(err.response.data.errors);
  //           });
  //       }
  //     } catch (err) {
  //       // console.log(err);
  //     }
  //   };

  //   const { getToken, isSignedIn } = useAuth();
  //   useEffect(() => {
  //     getData();
  //   }, [clerkUser]);

  //   const navigate = useNavigate();

  //

  const isSignedIn = localStorage.getItem("token");

  const { token } = useContext(AuthContext);

  const routes = createBrowserRouter([
    {
      path: "",
      element: token ? <AuthLayout /> : <Layout />,
      children: [
        {
          index: true,
          element: !isSignedIn ? (
            <ProtectedLogin>
              <Homepage />
            </ProtectedLogin>
          ) : (
            <ProtectedRoutes>
              <MyFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "home",
          element: !isSignedIn ? (
            <ProtectedLogin>
              <HomeComponent />
            </ProtectedLogin>
          ) : (
            <ProtectedRoutes>
              <MyFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "shared-fronds",
          element: (
            <ProtectedRoutes>
              <SharedFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "archived-fronds",
          element: (
            <ProtectedRoutes>
              <ArchiveFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "create-frond",
          element: (
            <ProtectedRoutes>
              <CreateFrond />
            </ProtectedRoutes>
          ),
        },
        {
          path: "login",
          element: (
            <ProtectedLogin>
              <Login />
            </ProtectedLogin>
          ),
        },
        {
          path: "signup",
          element: (
            <ProtectedLogin>
              <Register />
            </ProtectedLogin>
          ),
        },
        {
          path: "forget-password",
          element: (
            <ProtectedLogin>
              <ForgetPassword />
            </ProtectedLogin>
          ),
        },
        {
          path: "verify-password",
          element: (
            <ProtectedLogin>
              <ProtectedVerify>
                <VerifyPassword />
              </ProtectedVerify>
            </ProtectedLogin>
          ),
        },
        {
          path: "events",
          element: <Events />,
        },
        {
          path: "ventures",
          element: <Ventures />,
        },
        {
          path: "escape",
          element: <Escape />,
        },
        {
          path: "download",
          element: <Download />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        {
          path: "how-frond-works",
          element: <HowFrondWorks />,
        },
        {
          path: "faq",
          element: <FAQ />,
        },
      ],
    },
  ]);

  return (
    <>
      <NextUIProvider>
        <RouterProvider router={routes}></RouterProvider>
      </NextUIProvider>
    </>
  );
};

/*

const clerkUser = useUser();

    const {getToken, isSignedIn} = useAuth();
    const [selected, setSelected] = React.useState("myFronds");
    const {pathname} = useLocation();

    const db = getFirestore();

    const RouteWithSidebar = ({component: Component, ...rest}) => {
        const [loaded, setLoaded] = React.useState(false);
        React.useEffect(() => {
            const timer = setTimeout(() => setLoaded(true), 4000);
            return () => clearTimeout(timer);
        }, []);
        return (
            <Route
                {...rest}
                render={props => (
                    <>
                        <Layout>
                            {!loaded ? (
                                <Loader/>
                            ) : (
                                <>
                                    <Header/>

                                    <Component {...props} />
                                </>
                            )}
                        </Layout>
                    </>
                )}
            />
        );
    };

    const RouteFullPage = ({component: Component, ...rest}) => {
        const [loaded, setLoaded] = React.useState(false);

        React.useEffect(() => {
            const timer = setTimeout(() => setLoaded(true), 4000);
            return () => clearTimeout(timer);
        }, []);
        // console.log(loaded);
        return (
            <Route
                {...rest}
                render={props => (
                    <>
                        <Layout>
                            {!loaded ? (
                                <Loader/>
                            ) : (
                                <>
                                    <Component {...props} />
                                </>
                            )}
                        </Layout>
                    </>
                )}
            />
        );
    };

    useEffect(() => {
        axios.defaults.baseURL = "https://frond-admin.code-minds.tech/api";
        const getData = async function () {
            try {
                // const firebaseToken = clerkUser.user.getSessions();
                const auth = getAuth();

                const token = await getToken({template: "integration_firebase"});
                const userCredentials = await signInWithCustomToken(auth, token);
                if (clerkUser.isSignedIn) {
                    axios
                        .post("/user", {
                            name: clerkUser.user.fullName,
                            email: clerkUser.user.primaryEmailAddress.emailAddress,
                            password: clerkUser.user.id,
                        })
                        .then(res => {
                            let authToken = localStorage.setItem(
                                "token",
                                res.data.response.data.token,
                            );
                            axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
                        })
                        .catch(err => {
                            // console.log(err.response.data.errors);
                        });
                }
            } catch (err) {
                // console.log(err);
            }
        };

        getData();
    }, [clerkUser]);
    // console.log(pathname);
    return (
        <NextUIProvider>
            <ChakraProvider>
                {!isSignedIn ? <Header/> : <AuthNavbar/>}
                {isSignedIn && (
                    <Tabs
                        aria-label="Tabs"
                        selectedKey={pathname}
                        classNames={{
                            cursor: "w-full bg-primaryBlue ",
                            tabContent:
                                "group-data-[selected=true]:text-[#fff] group-data-[selected=false]:text-[#2B2B2A]",
                            base: "sticky top-0",
                        }}
                        variant="light"
                        size="lg"
                        fullWidth
                        radius="lg"
                    >
                        <Tab
                            key="/"
                            id="/"
                            as={Link}
                            href="/"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Target/>

                                    <span>My fronds</span>
                                </div>
                            }
                        />
                        <Tab
                            key="/createfrond"
                            id="/createfrond"
                            href="/createfrond"
                            title={
                                <div className="flex items-center space-x-2">
                                    <SquarePen/>

                                    <span>Create a frond</span>
                                </div>
                            }
                        />
                        <Tab
                            key="sharedFronds"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Share2/>

                                    <span>Shared fronds</span>
                                </div>
                            }
                        />
                        <Tab
                            key="archivedFronds"
                            title={
                                <div className="flex items-center space-x-2">
                                    <ArchiveX/>

                                    <span>Archived fronds</span>
                                </div>
                            }
                        />
                        <Tab
                            key="invitations"
                            title={
                                <div className="flex items-center space-x-2">
                                    <UserPlus/>

                                    <span>Invitations</span>
                                </div>
                            }
                        />
                        <Tab
                            key="discover"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Target/>

                                    <span>Discover</span>
                                </div>
                            }
                        />
                        <Tab
                            key="community"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Users/>

                                    <span>Community</span>
                                </div>
                            }
                        />
                    </Tabs>
                )}
                <Switch>
                    {isSignedIn ? (
                        <>
                            {/* <RouteFullPage
                  component={Dashboard}
                  path={Routes.Home.path}
                  exact
                /> }
                <RouteFullPage component={MyFrond} path={"/"} exact/>
                <RouteFullPage
                    component={CreateAFrond}
                    path={"/createfrond"}
                    exact
                />
            </>
        ) : (
            <>
                <RouteFullPage
                    component={Homepage}
                    path={Routes.Home.path}
                    exact
                />
                <RouteFullPage
                    component={Workpage}
                    path={Routes.Work.path}
                    exact
                />
                <RouteFullPage
                    component={Celebratepage}
                    path={Routes.Celebrate.path}
                    exact
                />
                <RouteFullPage
                    component={Pricingpage}
                    path={Routes.Pricing.path}
                    exact
                />
                <RouteFullPage
                    component={Contactpage}
                    path={Routes.Contact.path}
                    exact
                />
                <RouteFullPage
                    component={Escapepage}
                    path={Routes.Escape.path}
                    exact
                />
                <RouteFullPage
                    component={Homeplaning}
                    path={Routes.Homeplaning.path}
                    exact
                />
            </>
        )}
    </Switch>
</ChakraProvider>
</NextUIProvider>
);
*/
