import React from "react";
import CardsTemplate from "./CardsTemplate";

const HomeComponent = () => {
  const cardsImgs = require.context("../../Assets/template/home", true);
  const cardsImgsList = cardsImgs.keys().map((image) => cardsImgs(image));
  const cardOne = {
    title: "Outgrowing your home? Frond helps families plan for the future",
    content: [
      {
        space: true,
        info: "Dream bigger",
        desc: "Manage renovations and expansions seamlessly to create a space that grows with your family",
      },
      {
        space: true,
        info: "Stay organised",
        desc: "Centralise plans, budgets, and contractor details for a stress-free project",
      },
      {
        info: "Love where you live",
        desc: "Turn your house into a perfect family home, from inspiration to completion",
      },
    ],
    img: cardsImgsList[0],
  };
  const cardTwo = {
    title:
      "Don't go it alone. frond keeps you and your partner on the same page",
    content: [
      {
        space: true,
        info: "Dream together",
        desc: "Share ideas, inspiration, and photos instantly, no matter where you are",
      },
      {
        space: true,
        info: "Effortless communication",
        desc: "Ditch the endless emails. Discuss plans, comment on ideas, and stay in sync with ease",
      },
      {
        info: "Never lose track",
        desc: "Centralise everything in one place, so nothing gets lost in the shuffle",
      },
    ],
    img: cardsImgsList[1],
  };
  const cardThree = {
    title:
      "No more renovation scramble, frond keeps everything organised in one place.",
    content: [
      {
        space: true,
        info: "Ditch the paper mess",
        desc: "Consolidate notes, photos, and budgets into one user-friendly app",
      },
      {
        space: true,
        info: "Find anything fast",
        desc: "Say goodbye to lost notes and forgotten ideas. Frond keeps everything at your fingertips",
      },
      {
        info: "Collaborate seamlessly",
        desc: "Share plans with friends, family, or professionals for valuable inpu",
      },
    ],
    img: cardsImgsList[2],
  };
  return (
    <CardsTemplate
      secName="home"
      mainTitle={
        "Your Home Renovation Journey Starts with frond Plan It, Build It"
      }
      cardOne={cardOne}
      cardTwo={cardTwo}
      cardThree={cardThree}
      subTitle={
        "Centralise Ideas, Tasks, and budgets with frond Organise Your Renovation"
      }
    />
  );
};

export default HomeComponent;
