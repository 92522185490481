import React, { useEffect, useState } from "react";
import people from "../../Assets/contact-us/people.svg";
import card from "../../Assets/contact-us/card.svg";
import { Image, Input, Textarea, button } from "@nextui-org/react";
import arrow from "../../Assets/contact-us/arrow.svg";
import egFlag from "../../Assets/contact-us/egypt-flag.svg";
import axios from "axios";

const ContactUs = () => {
  const [isCountryError, setIsCountryError] = useState("");
  const [countries, setCountries] = useState([]);

  async function getCountries() {
    try {
      const { data } = await axios.get("https://restcountries.com/v3.1/all");

      setCountries(data);
    } catch (error) {
      setIsCountryError("Error");
    }
  }

  useEffect(() => {
    getCountries();
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const [countryCode, setCountryCode] = useState("+20");
  const [countryFlag, setCountryFlag] = useState(egFlag);

  return (
    <section className="contact-us relative">
      <div className="intro bg-mainGradiant py-16">
        <div className="container xl:px-40 px-6 mx-auto relative z-20">
          <div className="flex items-center justify-between md:flex-nowrap flex-wrap gap-x-5 gap-y-7">
            <div className="text-white flex-1">
              <h2 className="sm:text-[58px] text-[40px] font-bold text-white mb-9">
                Your voice matters!
              </h2>
              <p className="text-2xl font-bold">
                Help shape the future of frond.
              </p>
            </div>
            <div className="md:mx-0 mx-auto">
              <Image src={people} alt="People" />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-box relative py-14">
        <div className="absolute left-0 bottom-0 bg-contactBuble sm:w-[344px] h-[384px] w-[300px] contact-buble"></div>
        <div className="info text-center mb-14">
          <div className="container xl:px-44 px-6 mx-auto relative z-20">
            <p className="text-lg text-textColor mb-0.5 font-normal opacity-80">
              <strong className="font-bold me-1">Share your experience:</strong>
              We love hearing how you use frond and your ideas for improvement.
            </p>
            <p className="text-lg text-textColor mb-0.5 font-normal opacity-80">
              <strong className="font-bold me-1">
                Direct line to the top:
              </strong>
              All feedback goes straight to our founders, Nev and Mark, who are
              passionate about making frond even better.
            </p>
            <p className="text-lg text-textColor mb-0.5 font-normal opacity-80">
              <strong className="font-bold me-1">Personalised response:</strong>
              Expect a personal reply from Nev or Mark, showing we value your
              input.
            </p>
          </div>
        </div>
        <div className="container xl:px-32 px-6 mx-auto relative z-20">
          <div className="contact-form grid lg:grid-cols-2 gap-y-8 items-center">
            <div className="md:mx-0 mx-auto">
              <Image src={card} alt="Card" />
            </div>
            <div className="form">
              <div className="flex items-center gap-10 mb-6 contact-form-input-container">
                <label
                  htmlFor="fName"
                  className="text-lg text-black opacity-75 min-w-[74px]"
                >
                  Name
                </label>
                <div className="flex gap-5 items-center sm:flex-nowrap flex-wrap w-full">
                  <Input
                    id="fName"
                    name="fName"
                    type="text"
                    variant="bordered"
                    placeholder="First Name"
                    className="sm:w-1/2"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                  />
                  <Input
                    name="lName"
                    type="text"
                    variant="bordered"
                    placeholder="Last Name"
                    className="sm:w-1/2"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center gap-10 mb-6 contact-form-input-container">
                <label
                  htmlFor="email"
                  className="text-lg text-black opacity-75 min-w-[74px]"
                >
                  Email
                </label>
                <div className="w-full">
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    variant="bordered"
                    placeholder="example@mail.com"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center gap-10 mb-6 contact-form-input-container">
                <label
                  htmlFor="phone"
                  className="text-lg text-black opacity-75 min-w-[74px]"
                >
                  Phone
                </label>
                <div className="w-full relative">
                  <Input
                    id="phone"
                    name="phone"
                    type="tel"
                    variant="bordered"
                    placeholder="000 - 0000 - 000"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                    startContent={
                      <button onClick={() => setMenuOpen(!menuOpen)}>
                        <div className="flex items-center gap-2 ps-5 pe-2 border-r-1 border-solid border-cardColor">
                          <Image
                            src={arrow}
                            alt="arrow"
                            className="min-w-[12px] h-[12px]"
                          />
                          <Image
                            src={countryFlag}
                            alt="Eygpt Flag"
                            className="min-w-[21px] h-[21px]"
                          />
                          <span className="text-cardGrayColor text-sm">
                            {countryCode}
                          </span>
                        </div>
                      </button>
                    }
                  />
                  {countries.length > 0 && !isCountryError ? (
                    <ul
                      className={`list-none code absolute left-0 top-[40px] bg-cardColor transition-all duration-300 z-30 ${
                        menuOpen ? "h-[170px] py-2" : "h-0 py-0"
                      } overflow-y-auto flex flex-col gap-2`}
                    >
                      {countries.map((country, idx) => (
                        <button
                          key={idx}
                          onClick={() => {
                            setCountryCode(
                              country.idd.root +
                                (country.idd.suffixes
                                  ? country.idd.suffixes[0]
                                  : "")
                            );
                            setCountryFlag(country.flags.svg);
                            setMenuOpen(false);
                          }}
                        >
                          <li className="flex items-center gap-2 px-[17px] py-3 bg-secondaryGray">
                            <Image
                              src={country.flags.svg}
                              alt="Eygpt Flag"
                              className="min-w-[21px] max-w-[21px] h-[21px]"
                            />
                            <span className="text-black opacity-75 font-bold text-sm">
                              {country.idd.root +
                                (country.idd.suffixes
                                  ? country.idd.suffixes[0]
                                  : "")}
                            </span>
                          </li>
                        </button>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </div>
              <div className="flex items-start gap-10 mb-6 contact-form-input-container relative z-20">
                <label
                  htmlFor="message"
                  className="text-lg text-black opacity-75 min-w-[74px]"
                >
                  Message
                </label>
                <div className="w-full">
                  <Textarea
                    id="message"
                    name="message"
                    variant="bordered"
                    placeholder="Enter your message here..."
                    classNames={{
                      innerWrapper: "h-[168px]",
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="text-lg text-white font-bold py-3 px-5 rounded-4xl bg-mainGradiant"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
