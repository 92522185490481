import { Image } from "@nextui-org/react";
import React, { useState } from "react";
import mainImg from "../../Assets/frond-works/mainImg.svg";
import createFrond from "../../Assets/frond-works/createFrond.svg";

const HowFrondWorks = () => {
  const tabsIconsActive = require.context(
    "../../Assets/frond-works/icons/active",
    true
  );
  const tabsIconsNonActive = require.context(
    "../../Assets/frond-works/icons/nonactive",
    true
  );
  const tabsIconsActiveList = tabsIconsActive
    .keys()
    .map((image) => tabsIconsActive(image));
  const tabsIconsNonActiveList = tabsIconsNonActive
    .keys()
    .map((image) => tabsIconsNonActive(image));
  const tabsData = [
    {
      name: "Register",
    },
    {
      name: "Create",
    },
    {
      name: "Discover",
    },
    {
      name: "Share",
    },
  ];
  const [activeTab, setActiveTab] = useState("Register");
  return (
    <section className="download relative">
      <div className="absolute left-0 bottom-0 md:w-[671px] sm:[w-630px] w-[80%] h-[614px] z-10 bg-frondWorksBuble"></div>
      <div className="intro bg-mainGradiant py-24">
        <h2 className="text-[58px] font-bold text-white text-center lg:w-1/2 md:w-3/4 mx-auto px-4">
          How frond works
        </h2>
      </div>
      <div className="content py-12 relative z-20">
        <div className="container xl:px-36 md:px-3 px-6 mx-auto mb-12">
          <div className="tabs grid md:grid-cols-4 grid-cols-2 items-center justify-center gap-14 mb-12 w-fit mx-auto">
            {tabsData.map((item, idx) => (
              <button key={idx} onClick={() => setActiveTab(item.name)}>
                <div className="flex flex-col items-center gap-y-3">
                  <button
                    className={`flex justify-center items-center rounded-[24px] w-[78px] h-[78px] transition-all ${
                      item.name === activeTab
                        ? "bg-darkBlue shadow-frondWorksDark"
                        : "bg-offWhite shadow-frondWorksLight"
                    }`}
                    style={{
                      transition: "background 0.3s",
                    }}
                  >
                    <img
                      src={
                        item.name === activeTab
                          ? tabsIconsActiveList[idx]
                          : tabsIconsNonActiveList[idx]
                      }
                      className="min-w-[38px] min-h-[38px] max-w-[38px] max-h-[38px]"
                      style={{ color: "red", fill: "red" }}
                    />
                  </button>
                  <span
                    className={`text-2xl block w-[100px] text-center ${
                      item.name === activeTab
                        ? "text-darkBlue font-bold"
                        : "text-lightBlue font-normal"
                    }`}
                  >
                    {item.name}
                  </span>
                </div>
              </button>
            ))}
          </div>
          <div className="create-frond rounded-4xl bg-white border-2 border-solid border-cardColor py-10 px-[60px] shadow-mainShadow">
            <div className="grid md:grid-cols-2 items-center justify-between gap-y-7">
              <div className="info">
                <h3 className="bg-clip-text text-transparent bg-mainGradiant text-38px mb-4 font-bold">
                  Create your frond
                </h3>
                <p className="text-lg leading-[25.2px]">
                  Type notes, add images, clip web pages, or record memos. All
                  in one place.
                </p>
              </div>
              <div className="md:ms-auto w-fit">
                <Image src={createFrond} alt="create frond" />
              </div>
            </div>
          </div>
        </div>
        <div className="container xl:px-44 px-16 mx-auto">
          <button type="button">
            <Image src={mainImg} alt="Keep Track" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default HowFrondWorks;
