import { Image, Input } from "@nextui-org/react";
import React from "react";
import magnifier from "../../Assets/filter/magnifier.svg";
import filterSearch from "../../Assets/filter/filter-search.svg";
import sort from "../../Assets/filter/sort.svg";
import upArrow from "../../Assets/filter/up.svg";
import activeGrid from "../../Assets/filter/activeGrid.svg";
import nonActiveGrid from "../../Assets/filter/nonActiveGrid.svg";
import activeSquare from "../../Assets/filter/activeSquare.svg";
import nonActiveSquare from "../../Assets/filter/nonActiveSquare.svg";
import deleteIcon from "../../Assets/filter/delete-folder.svg";
import archiveIcon from "../../Assets/filter/archive.svg";
import { Link } from "react-router-dom";

const ProjectControl = ({ actions }) => {
  return (
    <div className="w-full flex justify-between items-center gap-10 mb-5">
      <div className="search flex items-center gap-5">
        <Input
          type="text"
          placeholder="Search projects..."
          classNames={{
            base: "w-[284px]",
            input: "border-none p-0",
            inputWrapper: "px-15px py-2.5 bg-white rounded-[20px] in-wrap",
            mainWrapper:
              "bg-white rounded-[20px] border-2 border-solid border-grayBorder",
          }}
          startContent={
            <Image
              src={magnifier}
              alt="magnifier"
              className="w-[18px] h-[18px]"
            />
          }
          className="search-projects"
        />
        <button className="flex justify-between items-center gap-10 border-b-1 border-solid border-mainBlue">
          <div className="icon-title flex items-center gap-2 mb-0.5">
            <Image src={filterSearch} alt="Filter" />
            <span className="text-mainBlue font-normal text-base">Filter</span>
          </div>
          <Image className="mb-0.5" src={upArrow} alt="^" />
        </button>
        <button className="flex justify-between items-center gap-10 border-b-1 border-solid border-mainBlue">
          <div className="icon-title flex items-center gap-2 mb-0.5">
            <Image src={sort} alt="Sort" />
            <span className="text-mainBlue font-normal text-base">Sort</span>
          </div>
          <Image className="mb-0.5" src={upArrow} alt="^" />
        </button>
      </div>
      <div className="options flex items-center gap-5">
        <div className="show flex items-center gap-2">
          <Image src={activeSquare} alt="Square" />
          <Image src={nonActiveGrid} alt="Grid" />
        </div>
        {actions && (
          <div className="actions flex gap-3 items-center">
            {actions.map((item, idx) => (
              <Link
                key={idx}
                to={""}
                className={`relative flex justify-center items-center gap-3 rounded-4xl py-[11px] px-5 gradiantBorder smallBorder`}
              >
                <img
                  src={
                    item === "Archive"
                      ? archiveIcon
                      : item === "Delete"
                      ? deleteIcon
                      : null
                  }
                  alt={item}
                  className="w-[18px] h-[18px]"
                />
                <span className="bg-clip-text text-transparent bg-mainGradiant text-base font-normal">
                  {item}
                </span>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectControl;
