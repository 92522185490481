import React, { useState } from "react";
import { Image } from "@nextui-org/react";
import { motion } from "framer-motion";
import FadeIn from "react-fade-in";

// Image source
import Logo from "../../Assets/Images/logo.png";

// Loading screen
export const Loader = ({ show }) => {
  const [done, setDone] = useState(false);

  React.useEffect(() => {
    let timeout = setTimeout(() => setDone(true), 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div
      className="w-screen flex justify-center items-center"
      style={{ height: "calc(100vh - 108.8px)" }}
    >
      <FadeIn>
        <div className="flex flex-col justify-center items-center">
          {!done ? (
            <>
              <motion.div
                className="box"
                initial={{ opacity: 0.5, scale: 1 }}
                animate={{
                  opacity: [0.5, 1, 0.5],
                  scale: [1, 1.5, 1],
                }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "mirror",
                }}
              >
                <Image src={Logo} width={100} className="rounded-none" />
              </motion.div>
            </>
          ) : (
            <motion.div
              className="box"
              initial={{ opacity: 0.5, scale: 1 }}
              animate={{
                opacity: [0.5, 1, 0.5],
                scale: [1, 1.5, 1],
              }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "mirror",
              }}
            >
              <Image src={Logo} width={100} className="rounded-none" />
            </motion.div>
          )}
        </div>
      </FadeIn>
    </div>
  );
};
