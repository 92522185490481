import React, { useEffect, useState } from "react";
import axios from "axios";
import { Loader } from "components/Loader/Loader";
import ProjectControl from "components/ProjectControl/ProjectControl";

import mainImg from "../../Assets/my-fronds/frond/frond-img.svg";
import user1 from "../../Assets/my-fronds/frond/user-1.svg";
import user2 from "../../Assets/my-fronds/frond/user-2.svg";
import FrondCard from "components/FrondCard/FrondCard";

const SharedFronds = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  let authToken = localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
  const getSharedFronds = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/frond");
      setProducts(response.data.response.data);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage("Error");
    }
  };
  // useEffect(() => {
  //   getSharedFronds();
  // }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <section className="shared-fronds border-2 border-solid border-grayBorder bg-white rounded-4xl p-5">
          <ProjectControl />
          <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-5">
            <FrondCard
              mainImg={mainImg}
              title="Sara’s Wedding"
              category="Event"
              startDate="29 Nov, 2024"
              endDate="10 Dec, 2024"
              location="Alexandria, Egypt"
              userOne={user1}
              userTwo={user2}
              count="22"
              isShared={true}
            />
            <FrondCard
              mainImg={mainImg}
              title="Pyramids's trip"
              category="Event"
              startDate="29 Nov, 2024"
              endDate="10 Dec, 2024"
              location="Alexandria, Egypt"
              userOne={user1}
              userTwo={user2}
              count="22"
              isShared={true}
            />
            <FrondCard
              mainImg={mainImg}
              title="Sara’s Wedding"
              category="Event"
              startDate="29 Nov, 2024"
              endDate="10 Dec, 2024"
              location="Alexandria, Egypt"
              userOne={user1}
              userTwo={user2}
              count="22"
              isShared={true}
            />
            <FrondCard
              mainImg={mainImg}
              title="Sara’s Wedding"
              category="Event"
              startDate="29 Nov, 2024"
              endDate="10 Dec, 2024"
              location="Alexandria, Egypt"
              userOne={user1}
              userTwo={user2}
              count="22"
              isShared={true}
            />
            <FrondCard
              mainImg={mainImg}
              title="Sara’s Wedding"
              category="Event"
              startDate="29 Nov, 2024"
              endDate="10 Dec, 2024"
              location="Alexandria, Egypt"
              userOne={user1}
              userTwo={user2}
              count="22"
              isShared={true}
            />
          </div>
        </section>
      )}
    </>
  );
};

export default SharedFronds;
