import { createContext, useState } from "react";

export let AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [isVerified, setIsVerified] = useState("unknown");

  return (
    <AuthContext.Provider
      value={{ token, setToken, isVerified, setIsVerified }}
    >
      {children}
    </AuthContext.Provider>
  );
}
