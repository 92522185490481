import { Image } from "@nextui-org/react";
import React from "react";
import joinUs from "../../Assets/landing/join-us.svg";

const JoinFrond = () => {
  return (
    <section className="join-us pb-12">
      <div className="container xl:px-44 px-6 mx-auto">
        <div className="flex gap-x-5 gap-y-8 justify-center items-center lg:flex-nowrap flex-wrap">
          <div className="content sm:flex-1">
            <h2 className="text-38px font-bold bg-clip-text text-transparent bg-mainGradiant mb-3">
              Join the frond Community
            </h2>
            <p className="opacity-75 text-lg mb-10">
              Connect with the frond team for news & updates
            </p>
            <div className="connect flex gap-4 items-center sm:flex-nowrap flex-wrap">
              <input
                className="placeholder:text-cardGrayColor placeholder:text-lg placeholder:-ps-1 text-lg text-cardGrayColor sm:w-[386px] w-full h-[45px] rounded-[10px] border-1 border-solid border-cardColor focus:rounded-[10px] ps-3"
                type="email"
                placeholder="Email"
              />
              <button
                type="button"
                className="flex justify-center items-center bg-mainGradiant rounded-[10px] w-[159px] h-[45px] font-bold text-lg text-white"
              >
                Let’s Connect
              </button>
            </div>
          </div>
          <div className="join-img w-fit">
            <Image src={joinUs} alt="Join Us" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinFrond;
