import React from "react";
import mainImg from "../../Assets/frond-works/mainImg.svg";
import ask from "../../Assets/faq/ask.svg";
import cantFind from "../../Assets/faq/cantFind.svg";
import { Accordion, AccordionItem, Image } from "@nextui-org/react";
import upArrow from "../../Assets/faq/up.svg";
import downArrow from "../../Assets/faq/down.svg";
import { Link } from "react-router-dom";

const FAQ = () => {
  const faqData = [
    {
      title: "What is Frond?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
    {
      title: "What can Frond do?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
    {
      title: "How to install Frond app on my phone?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
    {
      title: "How to create an account?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
    {
      title: "How to create a new Frond?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
    {
      title: "How to invite people to my Frond?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
    {
      title: "How to edit, archive or delete your Frond?",
      content:
        "We are Plannit Limited trading as Frond, a company registered in England and Wales. Our company registration number is 10793986 and our registered office is at Kemp House, 152-160 City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT number is 281 4276 00",
    },
  ];

  const renderIndicator = ({ isOpen }) => {
    return <Image src={isOpen ? upArrow : downArrow} alt="arrow" />;
  };

  return (
    <section className="faq relative">
      <div className="intro bg-mainGradiant py-16">
        <div className="container xl:px-20 md:px-3 px-6 mx-auto mb-12">
          <div className="flex md:justify-between justify-center gap-x-7 gap-y-9 items-center md:flex-nowrap flex-wrap">
            <div className="text-white font-bold">
              <h2 className="text-[58px] mb-8">Ask Erda & Mia</h2>
              <p className="text-2xl leading-[27.6px]">
                Erda and Mia know everything about frond, they’ve heard us
                brainstorm and work endlessly to make frond pawfect. They will
                happily answer the FAQs and will feature in the chat with us
                feature…
              </p>
            </div>
            <Image
              src={ask}
              alt="Ask Frond"
              className="lg:min-w-[398px] md:min-w-[300px] ms:min-w-[398px] min-h-[217px]"
            />
          </div>
        </div>
      </div>
      <div className="faq-content py-14">
        <div className="container xl:px-56 lg:px-40 md:px-10 px-6 mx-auto mb-14">
          <Accordion
            defaultExpandedKeys={["0"]}
            className="border-2 border-solid border-cardColor shadow-mainShadow rounded-4xl sm:px-10 px-5 sm:py-[30px] py-[15px]"
            itemClasses={{
              trigger: `${"py-0"}`,
              title: "font-bold text-black sm:text-2xl text-lg",
              content:
                "text-[#404040] text-lg font-normal leading-[25.2px] px-4 mt-4",
            }}
          >
            {faqData.map((item, idx) => (
              <AccordionItem
                key={idx}
                aria-label={item.title}
                title={item.title}
                className={`${idx === faqData.length - 1 ? "mb-0" : "mb-5"} ${
                  idx === 0 ? "mt-0" : "mt-5"
                }`}
                disableIndicatorAnimation
                indicator={renderIndicator}
              >
                {item.content}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="container xl:px-40 lg:px-20 md:px-3 px-6 mx-auto">
          <div className="contact flex md:justify-between justify-center items-center gap-x-28 gap-y-8 md:flex-nowrap flex-wrap">
            <Image
              src={cantFind}
              alt="Cant't find"
              className="lg:min-w-[398px] sm:min-w-[300px] min-h-[217px]"
            />
            <div className="info">
              <h3 className="text-38px font-bold mb-8 text-textColor">
                Can't find what are you looking for?
              </h3>
              <Link
                to={"/contact-us"}
                className="text-white text-lg font-bold bg-mainGradiant rounded-[35px] w-[136px] h-[51px] flex justify-center items-center"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
