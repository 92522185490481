import React from "react";
import CreateFrondForm from "./CreateFrondForm";

export default function CreateAFrond() {
  return (
    <section className="bg-white border-2 border-solid border-grayBorder p-7 rounded-4xl">
      <CreateFrondForm />
    </section>
  );
}
