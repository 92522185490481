import React, { useContext, useEffect, useState } from "react";
import magnifier from "../../Assets/my-fronds/magnifier.svg";
import notification from "../../Assets/my-fronds/notification.svg";
import arrowDown from "../../Assets/my-fronds/down.svg";
import profilePic from "../../Assets/my-fronds/mask-group.svg";
import userIcon from "../../Assets/dropdown/navbar/user.svg";
import clickIcon from "../../Assets/dropdown/navbar/click.svg";
import moonIcon from "../../Assets/dropdown/navbar/moon.svg";
import sunIcon from "../../Assets/dropdown/navbar/sun.svg";
import moonIcon2 from "../../Assets/dropdown/navbar/moonIcon2.svg";
import sunIcon2 from "../../Assets/dropdown/navbar/sunIcon2.svg";
import exitIcon from "../../Assets/dropdown/navbar/exit.svg";

// import { Nav, Navbar, Container } from "react-bootstrap";
import { connect } from "react-redux";

// import { logout } from "../../store/actions/auth";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
  Image,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownTrigger,
  Input,
} from "@nextui-org/react";
// import {
//   SignIn,
//   SignInButton,
//   SignUpButton,
//   SignedIn,
//   SignedOut,
//   UserButton,
// } from "@clerk/clerk-react";
import Logo from "../../Assets/Images/logo.png";
// import {
//   Modal,
//   ModalContent,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   useDisclosure,
// } from "@nextui-org/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import { AuthContext } from "Context/AuthContext";

const ProfileNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { token, setToken } = useContext(AuthContext);
  const isSignedIn = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "token") {
        if (!event.newValue) {
          setToken(null);
          navigate("/login");
          localStorage.removeItem("token");
          sessionStorage.clear();
        } else if (localStorage.getItem("token") !== token) {
          setToken(null);
          navigate("/login");
          localStorage.removeItem("token");
          sessionStorage.clear();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const [theme, setTheme] = useState("dark");

  const { signOut } = useClerk();

  function logout() {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/login");
  }
  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      maxWidth="xl"
      style={{ height: "69px" }}
      className="shadow-navShadow bg-[rgba(255,255,255,.9)]"
    >
      <NavbarContent className="flex gap-9" justify="start w-fit">
        <NavbarBrand style={{ maxWidth: "98px", maxHeight: "28px" }}>
          <Link to={"/"}>
            <Image src={Logo} style={{ width: "98px", maxHeight: "28px" }} />
          </Link>
        </NavbarBrand>
        <NavbarItem className="search-navbar hidden sm:flex">
          <Input
            type="text"
            placeholder="Search frond..."
            classNames={{
              input: "border-none p-0",
              inputWrapper:
                "px-15px py-2.5 bg-[#F5F6FF] rounded-[20px] in-wrap",
              mainWrapper: "bg-[#F5F6FF] rounded-[20px]",
            }}
            startContent={<Image src={magnifier} alt="magnifier" />}
          />
        </NavbarItem>
      </NavbarContent>

      <NavbarContent justify="end w-fit" className="flex gap-9">
        <NavbarItem>
          <Link to={""}>
            <Image src={notification} alt="notification" />
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Dropdown
            classNames={{
              content: "min-w-[341px] max-w-[341px] p-5 rounded-[20px]",
            }}
            placement="bottom-end"
          >
            <DropdownTrigger>
              <button className="flex gap-1 items-center">
                <Image src={profilePic} alt="Profile Pic" />
                <Image src={arrowDown} alt="Arrow" />
              </button>
            </DropdownTrigger>
            <DropdownMenu aria-label="User Account" variant="light">
              <DropdownItem
                isReadOnly
                key="user Info"
                className="relative p-0 after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:h-px after:bg-grayBorder mb-[35px]"
              >
                <div className="flex gap-4 items-center">
                  <Image
                    src={profilePic}
                    alt="Profile Pic"
                    className="w-[50px] h-[50px] rounded-full"
                  />
                  <div className="user-data">
                    <h4 className="text-base font-bold text-textColor mb-0.5">
                      Maya Rodriguez
                    </h4>
                    <p className="text-cardGrayColor text-base font-normal">
                      your-e.mail@example.com
                    </p>
                  </div>
                </div>
              </DropdownItem>
              <DropdownItem
                isReadOnly
                key="Profile"
                className="relative p-0 after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:h-px after:bg-grayBorder mb-[30px]"
              >
                <div className="flex items-center gap-2 bg-[#F5F6FF] rounded-[10px] py-2 px-2.5">
                  <Image src={userIcon} alt="User" />
                  <p className="text-base font-normal text-textColor">
                    My Profile
                  </p>
                  <Image src={clickIcon} alt="Click" />
                </div>
              </DropdownItem>
              <DropdownItem
                isReadOnly
                key="Theme"
                className={`relative p-0 after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:h-px after:bg-grayBorder mb-[30px]`}
              >
                <h6 className="text-cardGrayColor text-sm font-bold mb-2">
                  Theme
                </h6>

                <div
                  className={`theme-box relative after:absolute after:bg-darkBlue after:w-1/2 ${
                    theme === "light" ? "after:left-0" : "after:left-[50%]"
                  } after:duration-300 after:transition-all after:top-0 after:bottom-0 after:rounded-[65px] after:z-10 flex items-center rounded-[65px] bg-[#F5F6FF]`}
                >
                  <button
                    onClick={() => {
                      setTheme("light");
                    }}
                    className="w-1/2 relative z-20 py-1"
                  >
                    <div className="light flex  gap-2 py-1 justify-center items-center relative z-20">
                      <div className="relative w-[24px] h-[24px]">
                        <img
                          src={sunIcon}
                          alt="Sun"
                          className={`absolute inset-0 transition-all duration-200 ${
                            theme === "light" ? "opacity-100" : "opacity-0"
                          }`}
                        />
                        <img
                          src={sunIcon2}
                          className={`absolute inset-0 transition-all duration-200 ${
                            theme === "light" ? "opacity-0" : "opacity-100"
                          }`}
                          alt="Sun"
                        />
                      </div>
                      <span
                        className={`text-base font-normal transition-all duration-300 ${
                          theme === "light" ? "text-white" : "text-darkBlue"
                        }`}
                      >
                        Light
                      </span>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      setTheme("dark");
                    }}
                    className="w-1/2 relative z-20 py-1"
                  >
                    <div className="dark flex gap-2 py-1 justify-center items-center relative z-20">
                      <div className="relative w-[24px] h-[24px]">
                        <img
                          src={moonIcon}
                          alt="Moon"
                          className={`absolute inset-0 transition-all duration-200 ${
                            theme === "dark" ? "opacity-0" : "opacity-1000"
                          }`}
                        />
                        <img
                          src={moonIcon2}
                          className={`absolute inset-0 transition-all duration-200 ${
                            theme === "dark" ? "opacity-100" : "opacity-0"
                          }`}
                          alt="Moon"
                        />
                      </div>
                      <span
                        className={`text-base font-normal transition-all duration-300 ${
                          theme === "dark" ? "text-white" : "text-darkBlue"
                        }`}
                      >
                        Dark
                      </span>
                    </div>
                  </button>
                </div>
              </DropdownItem>
              <DropdownItem isReadOnly key="Sign out" className="p-0">
                <button
                  onClick={() => {
                    signOut({ redirectUrl: "/" });
                    logout();
                  }}
                  className="flex items-center gap-3 py-2 px-2.5 w-full"
                >
                  <Image src={exitIcon} alt="Exit" />
                  <h6 className="text-textColor font-normal text-base">
                    Sign Out
                  </h6>
                </button>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent className="sm:hidden" justify="start w-fit">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>

      <NavbarMenu
        style={{ top: "69px", alignItems: "center" }}
        className={`py-5 sm:max-h-[176px] ${
          token ? "max-h-[212px]" : "max-h-[248px]"
        } rounded-b-15px`}
      >
        <NavbarMenuItem>
          <Link
            onClick={() => setIsMenuOpen(false)}
            className="w-full"
            to={"/"}
            size="lg"
          >
            item
          </Link>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
};

export default ProfileNavbar;
