import React, { useRef, useState } from "react";
// import { Form, FormLayout, Field } from "@saas-ui/react";

// import { DateRangeInput } from "@saas-ui/date-picker";
import "@eidellev/react-tag-input/dist/index.css";
import TagInput from "@eidellev/react-tag-input";
import { currency_list } from "./CurrencyList";

import { Input, Select, SelectItem, Textarea } from "@nextui-org/react";

import { Controller, useForm } from "react-hook-form";
import { Container } from "@chakra-ui/react";
import { Stack, HStack, VStack } from "@chakra-ui/react";

import { CircleDollarSign, Flag } from "lucide-react";
import { DateRangePicker } from "@nextui-org/react";
import { makeDeleteRequest, makeUploadRequest } from "./cloudinary";

import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import Doka. For testing purpose only, if you're intrested in using Doka
// in your project please purchase a license at https://pqina.nl/doka/
import "./vendor/doka.min.css";
import { create } from "./vendor/doka.esm.min";
// import FilePondPluginFileEncode from "filepond-plugin-file-encode";
// import { parseDate } from "@internationalized/date";
import axios from "axios";
import { Loader } from "components/Loader/Loader";

import uplodaIcon from "../../Assets/create-frond/Upload.svg";

// Register the FilePond plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit
  // FilePondPluginFileEncode,
);

export default function CreateFrondForm() {
  const [importanceList, setImportanceList] = useState([
    { label: "High", color: "red" },
    { label: "Medium", color: "blue" },
    { label: "Low", color: "green" },
  ]);

  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  // const [date, setDate] = React.useState({
  //   start: parseDate(""),
  //   end: parseDate(""),
  // });

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      date: "",
      category: "",
      location: "",
      budget: "",
      currency: "",
      importance: "",
      tags: "",
      desc: "",
    },
  });
  const onSubmit = async (data) => {
    setIsLoading(true);
    let authToken = localStorage.getItem("token");
    // console.log(authToken);
    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    // var formData = new FormData();
    // let uriParts = uploadedFile;
    // let fileType = uriParts[uriParts.length - 1];
    // formData.append("image", {
    //   uri: uploadedFile,
    //   name: `${authToken}.${fileType}`,
    //   type: `image/${fileType}`,
    // });

    await axios({
      method: "post",
      url: "/frond",
      data: {
        title: data.title,
        start_date: data.date.start,
        image: uploadedFile,
        end_date: data.date.end,
        category: data.category,
        location: data.location,
        budget: data.budget,
        currency: data.currency,
        importance: data.importance,
        tags: data.tags.map((tag) => tag.text),
        desc: data.desc,
      },
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((e) => {
        // this.setState({
        //   user: { ...this.state.user, image: e.data.response.data.image },
        // });
        formRef.current.reset();
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const categories = [
    "Home Planning",
    "Holiday Planning",
    "Work Planning",
    "Event Planning",
    "Others",
  ];

  const formRef = useRef(null);

  //Tags

  const [tags, setTags] = useState([]);

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    setValue("tags", newTags);
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    setTags(newTags);
    setValue("tags", newTags);
  };

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };

  const process = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort,
    transfer,
    options
  ) => {
    const abortRequest = makeUploadRequest({
      file,
      fieldName,
      successCallback: load,
      errorCallback: error,
      progressCallback: progress,
    });

    // var objectURL = URL.createObjectURL(file);

    return {
      abort: () => {
        abortRequest();
        abort();
      },
    };
  };
  const revert = (token, successCallback, errorCallback) => {
    makeDeleteRequest({
      token,
      successCallback,
      errorCallback,
    });
  };

  // console.log(files);

  return (
    <form>
      <div className="grid lg:grid-cols-3">
        <div className="filepond-wrapper">
          <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={false}
            allowFileEncode={false}
            onupdatefiles={setFiles}
            onpreparefile={(file, output) => {
              var filee = new File([output], "name");
              // console.log(filee);
              setUploadedFile(filee);
            }}
            imageResizeTargetWidth={200}
            acceptedFileTypes="image/*"
            name="file"
            imageResizeTargetHeight={144}
            imageResizeUpscale={false}
            imageResizeMode={"contain"}
            imageEditEditor={create({
              cropMinImageWidth: 128,
              cropMinImageHeight: 128,
            })}
            labelIdle={``}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3 flex flex-col gap-2"></div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <Controller
            name="title"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
              minLength: {
                value: 5,
                message: "Minimum length is 5 characters",
              },
              maxLength: {
                value: 40,
                message: "Maximum length is 40 characters",
              },
              pattern: {
                value: /^(?!.*\s{2})[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                message: "Title must contain only letters and spaces",
              },
              // max: 255,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                title="title"
                label="Title"
                placeholder="Enter a title for your project"
                labelPlacement="outside"
                classNames={{
                  input: [
                    "bg-transparent border-none ps-0",
                    "text-black/90 dark:text-white/90",
                    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                  ],
                }}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                type="text"
                isInvalid={errors.title?.message ? true : false}
                errorMessage={errors.title?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/3 px-3">
          <Controller
            name="date"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <DateRangePicker
                  label="Start Date - End Date"
                  classNames={{ base: "gap-y-1" }}
                  data-date-format="DD MM YYYY"
                  title="date"
                  visibleMonths={2}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  pageBehavior="single"
                  size="md"
                  placeholder="Enter start date and end date"
                  labelPlacement="outside"
                  isInvalid={errors.date?.message ? true : false}
                  errorMessage={errors.date?.message}
                />
              </>
            )}
          />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <Controller
            name="category"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Select
                  title="Category"
                  label="Category"
                  labelPlacement="outside"
                  placeholder="Choose a category"
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.category?.message ? true : false}
                  errorMessage={errors.category?.message}
                >
                  {categories.map((item) => (
                    <SelectItem value={item} key={item} textValue={item}>
                      {item}
                    </SelectItem>
                  ))}
                </Select>
              </>
            )}
          />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <Controller
            name="location"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
              minLength: {
                value: 5,
                message: "Minimum length is 5 characters",
              },
              maxLength: {
                value: 40,
                message: "Maximum length is 40 characters",
              },
              pattern: {
                value: /^(?!.*\s{2})[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                message: "Location must contain only letters and spaces",
              },
              // max: 255,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                title="Location"
                label="Location"
                labelPlacement="outside"
                placeholder="Location of the project"
                onBlur={onBlur}
                onChange={onChange}
                classNames={{
                  input: [
                    "bg-transparent border-none ps-0",
                    "text-black/90 dark:text-white/90",
                    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                  ],
                }}
                value={value}
                type="text"
                isInvalid={errors.location?.message ? true : false}
                errorMessage={errors.location?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <Controller
            name="budget"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
              pattern: {
                value: /^(?:[1-9][0-9]{0,5}|1000000)$/,
                message: "Budget must be a number between 1 and 1,000,000",
              },
              // max: 255,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                title="budget"
                label="Budget"
                labelPlacement="outside"
                onBlur={onBlur}
                startContent={<CircleDollarSign />}
                onChange={onChange}
                placeholder="Estimated Budget"
                classNames={{
                  input: [
                    "bg-transparent border-none",
                    "text-black/90 dark:text-white/90",
                    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                  ],
                }}
                value={value}
                type="text"
                isInvalid={errors.budget?.message ? true : false}
                errorMessage={errors.budget?.message}
              />
            )}
          />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <Controller
            name="currency"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Select
                  title="currency"
                  label="Currency"
                  labelPlacement="outside"
                  placeholder="Choose a currency"
                  onChange={onChange}
                  onBlur={onBlur}
                  startContent={<CircleDollarSign />}
                  isInvalid={errors.currency?.message ? true : false}
                  errorMessage={errors.currency?.message}
                >
                  {currency_list.map((currency) => {
                    return (
                      <SelectItem
                        value={currency.value}
                        key={currency.value}
                        textValue={currency.value}
                      >
                        {currency.label} ({currency.value})
                      </SelectItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <Controller
            name="importance"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Select
                  items={importanceList}
                  label="Importance"
                  labelPlacement="outside"
                  placeholder="Choose importance level "
                  onChange={onChange}
                  onBlur={onBlur}
                  isInvalid={errors.importance?.message ? true : false}
                  errorMessage={errors.importance?.message}
                >
                  {importanceList.map((imp) => {
                    return (
                      <SelectItem
                        key={imp.label}
                        textValue={imp.label}
                        value={imp.label}
                      >
                        <div className="flex gap-2 items-center">
                          <Flag color={imp.color} />

                          <div className="flex flex-col">
                            <span className="text-small">{imp.label}</span>
                          </div>
                        </div>
                      </SelectItem>
                    );
                  })}
                </Select>
              </>
            )}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <Controller
            name="tags"
            control={control}
            rules={{
              validate: (value) => {
                if (tags.length === 0) {
                  return "At least one tag is required";
                }
                for (let tag of tags) {
                  if (tag.text.length < 2 || tag.text.length > 10) {
                    return "Each tag must be between 2 and 10 characters";
                  }
                }
                return true;
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <label
                  className={`block ${
                    errors.tags ? "text-red-500" : "text-gray-700"
                  } text-sm mb-2`}
                  htmlFor="tags"
                >
                  Tags
                </label>
                <ReactTags
                  id="tags"
                  tags={tags}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  allowDragDrop={false}
                  inputFieldPosition="inline"
                  separators={["Enter", "Comma"]}
                  maxTags={10}
                  classNames={{
                    tagInputField: `${
                      errors.tags ? "error-tag" : "bg-gray-200"
                    } w-full rounded-lg border-none`,
                    tag: `p-2 bg-gray-400 rounded-lg inline-flex items-center gap-2 mb-2 me-2 ${
                      errors.tags ? "error-tag" : ""
                    }`,
                    editTagInput: "flex",
                    tagInput: "mt-2",
                  }}
                />
                {errors.tags && (
                  <p className="text-red-500 text-xs mt-0.5">
                    {errors.tags.message}
                  </p>
                )}
              </>
            )}
          />
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <Controller
            name="desc"
            control={control}
            rules={{
              required: { value: true, message: "This Field is required" },
              minLength: {
                value: 5,
                message: "Minimum length is 5 characters",
              },
              maxLength: {
                value: 150,
                message: "Maximum length is 150 characters",
              },
              pattern: {
                value: /^(?!.*\s{2})[A-Za-z]+(?:\s[A-Za-z]+)*$/,
                message: "Title must contain only letters and spaces",
              },
              // max: 255,
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Textarea
                title="desc"
                label="Description"
                labelPlacement="outside"
                onBlur={onBlur}
                placeholder="Describe your project"
                classNames={{
                  input: [
                    "bg-transparent border-none ps-0",
                    "text-black/90 dark:text-white/90",
                    "placeholder:text-default-700/50 dark:placeholder:text-white/60",
                  ],
                }}
                onChange={onChange}
                value={value}
                type="text"
                isInvalid={errors.desc?.message ? true : false}
                errorMessage={errors.desc?.message}
              ></Textarea>
            )}
          />
        </div>
      </div>

      <button
        // onClick={onSubmitHandler}
        onClick={handleSubmit(onSubmit)}
        // type="submit"
        class="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
      >
        Create
      </button>
    </form>
  );
}
