import React from "react";
import venture from "../../Assets/my-fronds/frond/category/venture.svg";
import other from "../../Assets/my-fronds/frond/category/other.svg";
import home from "../../Assets/my-fronds/frond/category/home.svg";
import event from "../../Assets/my-fronds/frond/category/event.svg";
import escape from "../../Assets/my-fronds/frond/category/escape.svg";
import exitRed from "../../Assets/dropdown/shared/exit-red.svg";

import calendar from "../../Assets/my-fronds/frond/calendar.svg";
import dots from "../../Assets/my-fronds/frond/dots.svg";
import geolocation from "../../Assets/my-fronds/frond/geolocation.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";

const FrondCard = ({
  mainImg,
  title,
  category,
  startDate,
  endDate,
  location,
  userOne,
  userTwo,
  count,
  isFronds,
  isShared,
  isArchive,
}) => {
  return (
    <div className="frond-card flex flex-col gap-y-2 relative p-3 bg-white rounded-[14px] border-2 border-solid border-grayBorder transition-all duration-300 hover:shadow-frondShadow">
      <div className="w-full h-[146px] rounded-[10px] mb-0.5">
        <img
          src={mainImg}
          alt={title}
          className="w-full h-full rounded-[10px] object-cover"
        />
      </div>
      {isShared && (
        <Dropdown
          classNames={{
            content: "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
          }}
          placement="left-start"
        >
          <DropdownTrigger>
            <button className="absolute w-[24px] h-[24px] top-5 right-5 z-20">
              <img src={dots} alt=":" />
            </button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Shared Fronds Actions">
            <DropdownItem
              key="share"
              className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px]"
            >
              Share
            </DropdownItem>
            <DropdownItem
              key="delete"
              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px]"
              color="danger"
            >
              <div className="flex items-center gap-2">
                <Image src={exitRed} alt="X" />
                <h6>Leave</h6>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {isArchive && (
        <Dropdown
          classNames={{
            content: "min-w-[92px] max-w-[92px] py-0 px-[5px] rounded-[10px]",
          }}
          placement="left-start"
        >
          <DropdownTrigger>
            <button className="absolute w-[24px] h-[24px] top-5 right-5 z-20">
              <img src={dots} alt=":" />
            </button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Archive Frond Actions">
            <DropdownItem
              key="share"
              className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px]"
            >
              Share
            </DropdownItem>
            <DropdownItem
              key="unArchive"
              className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px]"
            >
              unarchive
            </DropdownItem>
            <DropdownItem
              key="delete"
              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px]"
              color="danger"
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {isFronds && (
        <Dropdown
          classNames={{
            content: "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
          }}
          placement="left-start"
        >
          <DropdownTrigger>
            <button className="absolute w-[24px] h-[24px] top-5 right-5 z-20">
              <img src={dots} alt=":" />
            </button>
          </DropdownTrigger>
          <DropdownMenu aria-label="Frond Actions">
            <DropdownItem
              key="share"
              className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px]"
            >
              Share
            </DropdownItem>
            <DropdownItem
              key="archive"
              className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px]"
            >
              Archive
            </DropdownItem>
            <DropdownItem
              key="delete"
              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px]"
              color="danger"
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      <div className="content flex justify-between">
        <div className="info">
          <div className="name-category flex gap-4 items-center mb-1">
            <h5 className="text-textColor text-base font-bold">{title}</h5>
            <div className="flex items-center gap-2">
              <Image
                src={
                  category === "Event"
                    ? event
                    : category === "Escape"
                    ? escape
                    : category === "Home"
                    ? home
                    : category === "Venture"
                    ? venture
                    : category === "Other"
                    ? other
                    : null
                }
                alt={category}
              />
              <span className="text-cardGrayColor text-base font-normal">
                {category}
              </span>
            </div>
          </div>
          <div className="date flex items-center gap-1.5 ms-2.5 mb-1">
            <Image src={calendar} alt="Calendar" />
            <div className="flex items-center text-sm font-normal text-mainBlue opacity-75">
              <p>{startDate}</p>
              <span className="mx-1">-</span>
              <p>{endDate}</p>
            </div>
          </div>
          <div className="location flex items-center gap-1.5 ms-2.5">
            <Image src={geolocation} alt="Geolocation" />
            <p className="text-sm font-normal text-mainBlue opacity-75">
              {location}
            </p>
          </div>
        </div>
        <div className="users w-[66.38px] h-[24px] relative flex self-end justify-end items-center">
          <img
            src={userOne}
            alt="User one"
            className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] rounded-full absolute z-[10] left-[6.5px] top-0 count-border gradiantBorder"
          />
          <img
            src={userTwo}
            alt="User Two"
            className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] rounded-full absolute z-[11] right-[18px] top-0 count-border gradiantBorder"
          />
          <div className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] flex justify-center items-center count-border gradiantBorder rounded-full absolute z-[12] right-0 top-0">
            <span className="text-[10.5px] font-normal bg-clip-text text-transparent bg-mainGradiant">
              +{count - 2}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrondCard;
